<template>
  <div class="applyContainer van-page-bg-color global-div">
    <div class="applyNavBar">
      <van-grid v-if="dwsp_caption || wfqd_caption || bcxd_caption || wysp_caption" :width="750"
        :column-num="use_new_workflow ? 5 : 4">
        <van-grid-item v-if="dwsp_caption" :padding="false" :borderRight="true" @click="jump2('dwsp', '待我审批')">
          <template #icon>
            <van-badge :contentWidth="77" :contentHeight="77" :content="forcheckcount ? forcheckcount : null"
              style="text-align: center" :show-zero="false">
              <van-image :src="require('@assets/img/apply (2).png')" :scale="1" :width="'100%'"></van-image>
            </van-badge>
          </template>
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ dwsp_caption
              }}</span>
          </template>
        </van-grid-item>
        <van-grid-item v-if="wfqd_caption&&!use_new_workflow" :padding="false" :borderRight="true" @click="jump2('wfqd', '我发起的')">  
           <template #icon>
            <van-image :src="require('@assets/img/apply (1).png')" :scale="1" :width="'100%'"></van-image>
          </template>     
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ wfqd_caption
              }}</span>
          </template>
        </van-grid-item>
        <van-grid-item v-if="wysp_caption&&!use_new_workflow" :padding="false" :borderRight="true" @click="jump2('wysp', '我已审批')">  
           <template #icon>
            <van-image :src="require('@assets/img/apply (4).png')" :scale="1" :width="'100%'"></van-image>
          </template>     
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ wysp_caption
              }}</span>
          </template>
        </van-grid-item>
        <van-grid-item v-if="wtjd_caption && use_new_workflow" :padding="false" :borderRight="true"
          @click="jump2('wtjd', '未提交的')">
          <template #icon>
            <van-badge :contentWidth="77" :contentHeight="77" :content="wtj_count ? wtj_count : null"
              style="text-align: center" :show-zero="false">
              <van-image :src="require('@assets/img/apply (5).png')" :scale="1" :width="'100%'"></van-image>
            </van-badge>
          </template>
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ wtjd_caption
              }}</span>
          </template>
        </van-grid-item>

        <van-grid-item v-if="cs_caption&&use_new_workflow" :padding="false" :borderRight="true" @click="jump2('csdj', '抄送单据')">
          <template #icon>
            <van-badge :contentWidth="77" :contentHeight="77" :content="cs_count ? cs_count : null"
              style="text-align: center">
              <van-image :src="require('@assets/img/apply (1).png')" :scale="1" :width="'100%'"></van-image>
            </van-badge>
          </template>
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ cs_caption
              }}</span>
          </template>
        </van-grid-item>
        
        <van-grid-item v-if="bcxd_caption" :padding="false" @click="jump2('bcxd', '被撤销的')">
          <template #icon>
            <van-badge :contentWidth="77" :contentHeight="77" :content="bcxd_count ? bcxd_count : null"
              style="text-align: center">
              <van-image :src="require('@assets/img/apply (3).png')" :scale="1" :width="'100%'"></van-image>
            </van-badge>
          </template>
          <template #text>
            <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{ bcxd_caption
              }}</span>
          </template>
        </van-grid-item>

        <van-popover v-if="use_new_workflow" :class="use_new_workflow ? 'hjewweffwew5' : 'hjewweffwew4'" v-model="showPicker" trigger="click" :actions="columns" @select="onConfirm">
          <template #reference>
            <van-grid-item v-if="wfqd_caption || wysp_caption" :padding="false" :borderRight="true">
              <template #icon>
                <van-badge :contentWidth="77" :contentHeight="77" :num="0" style="text-align: center">
                  <van-image :src="require('@assets/img/apply (1).png')" :scale="1" :width="'100%'"></van-image>
                </van-badge>
              </template>
              <template #text>
                <span class="van-grid-item__text" style="font-size: 14px; text-align: center; color: #000">{{
                  mydoc_caption }}</span>
              </template>
            </van-grid-item>
          </template>
        </van-popover>

      </van-grid>
      <van-search v-model="value" placeholder="请输入搜索关键词" @input="onSearch" />
    </div>

    <div v-if="isworkflowgroup">
      <div class="applyCollapse" v-for="(v, k) in workflowlist" :key="k">
        <van-collapse v-model="v.open">
          <van-collapse-item name="a">
            <template #title>
              <div class="apply-collapse-text-box">
                <span class="apply-collapse-text">{{ v.formtype }}(</span>
                <span class="apply-collapse-text van-color-primary">{{
                  v.list.length
                }}</span>
                <span class="apply-collapse-text">)</span>
              </div>
            </template>
            <div class="rowGrid">
              <van-grid :border="false" :column-num="5">
                <van-grid-item v-for="(value, key) in v.list" :key="key" @click="jump(value)"
                  style="text-align: center">
                  <svg-icon slot="icon" :icon-class="'plat' + value.appiconid"></svg-icon>
                  <template #text>
                    <span class="van-grid-item__text">{{ value.appname }}</span>
                  </template>
                </van-grid-item>
              </van-grid>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div v-else class="rowGrid">
      <van-grid :border="false" :column-num="5">
        <van-grid-item v-for="(value, key) in workflowlist" :key="key" @click="jump(value)">
          <svg-icon slot="icon" :icon-class="'plat' + value.appiconid"></svg-icon>
          <template #text>
            <span class="van-grid-item__text">{{ value.appname }}</span>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { GetWorkflowInfo } from '@/api/wx.js'
// import { setData, getData, delData } from '@/utils/index.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  data() {
    return {
      userInfo,
      value: '',
      forcheckcount: '',
      cs_count:0,
      bcxd_count:0,
      wtj_count: '',
      workflowlist: [],
      iniworkflowlist: [],
      ini2: [],
      isworkflowgroup: false,
      V3url: './',
      dwsp_caption: '待我审批',
      wtjd_caption: '未提交的',
      wfqd_caption: '我发起的',
      wysp_caption: '我已审批',
      bcxd_caption: '被撤销的',
      mydoc_caption: '我的单据',
      cs_caption: "抄送单据",
      showPicker:false,
      columns:[],
      scroll: 0, // 记录滚动的距离
    }
  },
  computed: {
    use_new_workflow () {
      if(process.env.NODE_ENV !== 'production'){
        return true;
      }else{
        if(window.use_new_workflow){
          return true;
        }else{
          return false;
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // 获取滚动距离
    this.scroll = document.getElementById("app").scrollTop
    console.log(this.scroll,'beforeRouteLeavebeforeRouteLeave')
    next()
  },
  activated () {
    // 当从详情返回时回到上次滚动距离
    this.$nextTick(() => {
      setTimeout(() => {
        document.getElementById("app").scrollTop = this.scroll
      }, 0)
    })
  },
  created() {
    // this.initData()
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[1].style.color =
        '#1989fa'
    }, 200)
  },
  watch:{
    '$route': {
      async handler(n) {
        if(n.path == '/apply'){
          await this.getWorkflowInfo().then(res => {})
        }
        // console.log(n,'fwfewfwe')
      },
      immediate: true
    }
  },
  methods: {
    onConfirm (v) {
      console.log(v);
      if(v.id == "wfqd_caption"){
        this.jump2('wfqd', '我发起的')
      }else if(v.id == "wysp_caption"){
        this.jump2('wysp', '我已审批')
      }
      this.showPicker = false;
    },
    async initData() {
      // 审批流列表
      await this.getWorkflowInfo().then(res => {})
    },
    async getWorkflowInfo() {
      await GetWorkflowInfo({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.workflowlist = []
        this.forcheckcount = res.forcheckcount[0].cnt || 0
        this.wtj_count = res.forcheckcount[0].wtj_count || 0
        this.cs_count = res.forcheckcount[0].cs_count || 0
        this.bcxd_count = res.forcheckcount[0].bcxd_count || 0
        if ('dwsp_caption' in res.forcheckcount[0]) {
          this.dwsp_caption = res.forcheckcount[0].dwsp_caption
          this.wfqd_caption = res.forcheckcount[0].wfqd_caption
          this.wysp_caption = res.forcheckcount[0].wysp_caption
          this.columns = [
            {
              id:"wfqd_caption",
              text:this.wfqd_caption,
              icon: 'records'
            },
            {
              id:"wysp_caption",
              text:this.wysp_caption,
              icon: 'completed'
            }
          ]
          this.bcxd_caption = res.forcheckcount[0].bcxd_caption
          this.wtjd_caption = res.forcheckcount[0].wtjd_caption || '未提交的'
          this.cs_caption = res.forcheckcount[0].cs_caption
          this.mydoc_caption = res.forcheckcount[0].mydoc_caption
          this.isworkflowgroup = res.forcheckcount[0].use_wb_wf_category
          localStorage.setItem(
            'dwsp_caption',
            res.forcheckcount[0].dwsp_caption
          )
          localStorage.setItem(
            'cs_caption',
            res.forcheckcount[0].cs_caption
          )
          localStorage.setItem('wtjd_caption', this.wtjd_caption)
          localStorage.setItem(
            'wfqd_caption',
            res.forcheckcount[0].wfqd_caption
          )
          localStorage.setItem(
            'wysp_caption',
            res.forcheckcount[0].wysp_caption
          )
          localStorage.setItem(
            'bcxd_caption',
            res.forcheckcount[0].bcxd_caption
          )
          localStorage.setItem(
            'use_wb_wf_category',
            res.forcheckcount[0].use_wb_wf_category
          )
        }
        let arr = [],
          formtype = null
        res.workflowlist.forEach((e, i) => {
          if (!('dwsp_caption' in res.forcheckcount[0])) {
            if (i == 0) {
              // 是否分组
              this.isworkflowgroup =
                e.isworkflowgroup === 1 || e.isworkflowgroup === true
            }
          }

          if (e.formtype != formtype) {
            formtype = e.formtype
            let obj = {
              formtype,
              open: ['a'], //  i == 0 ? ['a'] : [],
              list: []
            }
            obj.list = res.workflowlist.filter(
              item => item.formtype === formtype
            )
            arr.push(obj)
          }
        })
        if (this.isworkflowgroup) {
          this.workflowlist = arr
        } else {
          this.workflowlist = res.workflowlist
        }
        this.ini2 = JSON.parse(JSON.stringify(res.workflowlist))
        this.iniworkflowlist = JSON.parse(JSON.stringify(this.workflowlist))
      })
    },
    jump(v) {
      console.log(v,'jump')
      if(window.use_new_wf_form == 1 || process.env.NODE_ENV !== 'production'){
        const queryObj = {
          saveormodify:"save",
          mautoid:0,
          formautoid:v.autoid,
          formname:v.appname,
          nodeid:1,
          time: new Date().getTime(),
        }
        this.$router.push({
          path: '/workFlow',
          query: queryObj
        })
      }else{
        var url = 'shengpiinfo.html'
        var title = v.appname
        setLocVal('hyrptparas', title + '|' + '' + url + '|' + '' + v.autoid)
        let href = this.V3url + '/weixinlocal/queryrpt/' + url
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
      }
    },
    jump2(v, n) {
      // 是否启用新版的 【待审批，我发起，我已审，被驳回】
      const use_new_workflow = window.use_new_workflow || 0
      if (use_new_workflow || process.env.NODE_ENV !== 'production') {
        const arr = ['', 'dwsp', 'wfqd', 'wysp', 'bcxd', 'wtjd', 'csdj']
        if (v == 'dwsp' && !this.isworkflowgroup) {
          this.$router.push({
            path: '/wfList',
            query: { type: 1, formautoid: 0 }
          })
        } else {
          this.$router.push({
            path: '/wfClass',
            query: { type: arr.indexOf(v) }
          })
        }
      } else {
        var url = v + '.html'
        if (v == 'dwsp') url = 'dwspclass.html'
        let href = this.V3url + '/weixinlocal/queryrpt/' + url
        setLocVal('hyrptparas', n + '|' + '' + v + '|')
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
      }
    },
    //
    onSearch(val) {
      if (val) {
        const list = this.ini2.filter(e => {
          return e.appname.indexOf(val) > -1
        })
        console.log(this.ini2, list)
        if (this.isworkflowgroup) {
          this.workflowlist = [
            {
              formtype: '搜索结果',
              open: ['a'], //  i == 0 ? ['a'] : [],
              list
            }
          ]
        } else {
          this.workflowlist = list
        }
      } else {
        this.workflowlist = JSON.parse(JSON.stringify(this.iniworkflowlist))
      }
    },
    onCancel() {
      this.value = ''
      this.workflowlist = JSON.parse(JSON.stringify(this.iniworkflowlist))
    }
  }
}
</script>

<style lang="less" scoped>
.applyContainer {
  width: 100%;
  position: relative;
  padding-bottom: 142px;
}

.applyNavBar {
  width: 100%;
  background: #fff;
  padding: 16rpx 0 0;
  /deep/.van-popover__wrapper{
    &.hjewweffwew4{
      flex-basis: 25%;
    }
    &.hjewweffwew5{
      flex-basis: 20%;
    }
  }
  .van-grid-item {
    /deep/.van-grid-item__content {
      .van-grid-item__text {
        height: 30px;
        line-height: 1.1;
        /* max-width: 5em; */
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}

.applyCollapse {
  width: 100%;
  background: #fff;
  margin-top: 30rpx;
  padding: 16rpx 0;

  .rowGrid {
    font-size: 24px;
    font-family: Source Han Sans CN;
    color: #333;
    flex-wrap: nowrap !important;
    background: #fff;

    .van-grid-item {
      /deep/.van-grid-item__content {
        padding: 30px 0px;
        justify-content: start !important;

        span {
          max-width: 5em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    .svg-icon {
      width: 74px;
      height: 74px;
      margin-bottom: 12px;
    }
  }
}

.apply-collapse-header {
  width: 100%;
  height: 56rpx;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
}

.apply-collapse-left {
  height: 56rpx;
  flex: 2;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  padding-left: 32rpx;
}

.apply-collapse-text {
  font-size: 36rpx;
  font-weight: 900;
}

.apply-collapse-right {
  height: 56rpx;
  flex: 1;
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center !important;
  padding-right: 24rpx;
}

.apply-collapse-body {
  width: 100%;
  padding: 10rpx 32rpx;
}
</style>
